const defaultUnsetSettings = {
  intake: [
    'localization',
    'consentPageTextEnglish',
    'consentPageLogoOn',
    'consentPageLogo',
    'thankYouPageLogo',
    'disagreePageLogo',
    'qrCodePageLogo',
    'thankYouPageTextEnglish',
    'disagreePageTextEnglish',
    'reportMedsHigh',
    'useMedicalNameWhenReportingSymptoms',
    'smsFromPhoneNumber',
    'patientSupportEmailRecipient',
    'activeButtonThemeColor',
    'inactiveButtonThemeColor',
    'activeButtonHoverThemeColor',
    'unselectedButtonThemeColor',
    'primaryTheme',
    'bannerOn',
    'fontTheme',
    'languageOptions',
    'enableFeedbackText',
    'smsProvider',
    'consentPageTextArabic',
    'thankYouPageTextArabic',
    'disagreePageTextArabic',
    'privacyPolicyEnabled',
    'privacyPolicyTextEnglish',
    'privacyPolicyTextArabic',
    'linkExpiryTime',
    'consentPageTextFrenchCanadian',
    'thankYouPageTextFrenchCanadian',
    'disagreePageTextFrenchCanadian',
    'privacyPolicyTextFrenchCanadian',
    'customTheme',
    'disagreeButtonDisabled',
    'expiryHourOnAppointmentDate',
  ],
  medical: [
    'intakePlans',
    'qrCodeIntakePlan',
    'skipCaptcha',
    'maxSymptomsToExplore',
  ],
  geography: [
  ],
  api: [
    'accuroEnabled',
    'accuroPreBookedAutomationEnabled',
    'accuroSameDayAutomationEnabled',
    'accuroAccessToken',
    'accuroRefreshToken',
    'accuroAccessTokenExpireTime',
    'accuroRefreshTokenExpireTime',
    'accuroSentSMSStatus',
    'accuroNotSentSMSStatus',
    'accuroIntakeCompletedStatus',
    'accuroDaysBeforeAppointment',
    'accuroFolderId',
    'accuroSubfolderId',
    'accuroUUID',
    'accuroClientId',
    'accuroClientSecret',
    'accuroAppointmentType',
    'accuroSendDays',
    'accuroFilterByProviderIds',
    'accuroProviderIds',
    'notificationEnabled',
    'notificationSlackWebHookUrl',
    'notificationSlackWebHookMessage',
    'pushToEndpointIntegrationType',
    'pushToEndpointEnabled',
    'pushToEndpointUrl',
    'pushToEndpointToken',
    'pushToEndpointApiKey',
    'pushToEndpointApiSecret',
    'pccEnabled',
    'pccOrgUUID',
    'pccOrgId',
    'pccFacId',
    'sftpAutomationEnabled',
    'sftpHost',
    'sftpPort',
    'sftpUsername',
    'sftpPassword',
    'sftpRemoteDirectory',
    'canvasEnabled',
    'canvasClientId',
    'canvasClientSecret',
    'canvasDaysBeforeAppointment',
    'canvasPractitionerIds',
  ],
  hxManager: [
    'smsMessage',
    'canEditReport',
  ],
};

export default defaultUnsetSettings;
